// src/App.js
import React from "react";
class App extends React.Component 
{
  constructor(props) 
  {
    super(props);
    this.state = {
      date: new Date()
    }
  }

  componentDidMount() 
  {
    this.interval = setInterval( ()=> {
      this.setState({date: new Date()});
    }, 1000)
  }

  seconds_with_leading_zeros(dt) 
  { 
    return (dt.getSeconds() < 10 ? '0' : '') + dt.getSeconds();
  }

  minutes_with_leading_zeros(dt) 
  { 
    return (dt.getMinutes() < 10 ? '0' : '') + dt.getMinutes();
  }

  hours_with_leading_zeros(dt) 
  { 
    return (dt.getHours() < 10 ? '0' : '') + dt.getHours();
  }

  isFunnyNumber() {
    if (this.state.date.getHours() == 13 && this.state.date.getMinutes() == 37) {
      return (
        <h2 className="is-funny"> It <span>is</span> currently the funny number! Haha!</h2>
      )
    } else {
      return (
        <h2 className="not-funny">It currently <span>isn't</span> the funny number, sorry. Try again later.</h2>
      )
    }
  }

  render() {
    return (

        <div class='container'>
          <div class='row'>
            <div class='col-12'>
              <h3> The Funny Number </h3>
            </div>
          </div>
          
          <div class='row'>
            <div class='col-12'>
            <h2 className="q">Is it currently the funny number?</h2>
            </div>
          </div>
          <div class='row'>
            <div class='col-12'>
            <h1 className="funny-clock">{this.hours_with_leading_zeros(this.state.date)}:{this.minutes_with_leading_zeros(this.state.date)}:{this.seconds_with_leading_zeros(this.state.date)}</h1>
            </div>
          </div>
          <div class='row'>
            <div class='col-12'>
            {this.isFunnyNumber()}
            </div>
          </div>
          
          
          
        </div>
        

    )
  }
}

export default App;
